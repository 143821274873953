<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

/**
 * User list component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Assign Coach",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Assign Coach",
      items: [
        {
          text: "Assign coach",
        },
        {
          text: "Assign Coach",
          active: true,
        },
      ],
      users: [],
      optionsCoach: [],
      loadingUsers: false,
      savingCoach: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      //return this.userList.length;
      return 1;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        this.getUsers();
        this.getOptionsCoach();
      }
    });
  },
  methods: {
    async saveCoachAssign(user) {
      this.savingCoach = true;
      console.log(user.id + "- " + user.idCoach);
      var db = firebase.firestore();
      await db
        .collection("users")
        .doc(user.id)
        .update({ coach: user.idCoachTicket, coach_programs: user.idCoachPrograms })
        .then(() => {
          console.log("Document successfully updated!");
          this.savingCoach = false;
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    async getOptionsCoach() {
      var db = firebase.firestore();
      await db
        .collection("users")
        .where("role", "array-contains", "coach")
        .get()
        .then((coachs) => {
          coachs.forEach((coach) => {
            this.optionsCoach.push({
              value: coach.id,
              text: coach.data().first_name + " " + coach.data().last_name,
            });
          });
        });
      console.log("coach: " + JSON.stringify(this.optionsCoach));
    },
    getUsers() {
      this.loadingUsers = true;
      var db = firebase.firestore();
      var requete = db.collection("users").orderBy("first_name", "asc");
      requete = requete.where("role", "array-contains", "premium");

      requete.onSnapshot((users) => {
        this.users = [];
        users.forEach(async (user) => {
          var userData = user.data();
          var coach_tickets = userData.coach;
          var coach_programs = userData.coach_programs;
          this.users.push({
            id: user.id,
            data: userData,
            idCoachTicket: coach_tickets,
            idCoachPrograms: coach_programs
          });
          this.loadingUsers = false;
        });
      });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h2 class="my-3">Assign Coach</h2>

            <table class="table mb-4">
              <thead>
                <tr>
                  <th scope="col" width="50px">#</th>
                  <th scope="col">User</th>
                  <th scope="col">Coach Tickets</th>
                  <th scope="col">Coach Programs</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in users" :key="user.id + 'user'">
                  <td class="align-middle">
                    <img
                      v-if="user.data.photo_profil"
                      :src="user.data.photo_profil"
                      alt
                      class="avatar-xs rounded-circle me-2"
                    />
                    <div
                      v-if="!user.data.photo_profil"
                      class="avatar-xs d-inline-block me-2"
                    >
                      <div
                        class="
                          avatar-title
                          bg-soft-primary
                          rounded-circle
                          text-primary
                        "
                      >
                        <i class="mdi mdi-account-circle m-0"></i>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <router-link
                      :to="{ name: 'Profile', params: { id: user.id } }"
                    >
                      {{ user.data.first_name }}
                      {{ user.data.last_name }}
                    </router-link>
                  </td>
                  <td>
                    <b-form-select
                      v-model="user.idCoachTicket"
                      :options="optionsCoach"
                    ></b-form-select>
                  </td>
                  <td>
                    <b-form-select
                      v-model="user.idCoachPrograms"
                      :options="optionsCoach"
                    ></b-form-select>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-success"
                      @click="saveCoachAssign(user)"
                    >
                    <span
                    v-if="savingCoach"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                      <i class="fas fa-check"></i> Save
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
